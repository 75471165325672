/**
 * @ComponentFor HorizontalScrollContainerBlock
 */

import React from 'react';
import { EpiProperty } from '@avensia/scope-episerver';
import HorizontalScrollContainerBlock from './HorizontalScrollContainerBlock.type';
import { styled } from '@glitz/react';
import { beta, minMediumMediaQuery, small } from 'Shared/Style';

type PropType = HorizontalScrollContainerBlock;

const renderProp = (item: any, props: PropType) => {
  const children = React.Children.toArray(item.children).filter((child) => React.isValidElement(child));
  return (
    <Container {...item}>
      <Title>{props.title}</Title>
      <ItemsContainer>
        <Items>{children}</Items>
      </ItemsContainer>
    </Container>
  );
};
export default (props: PropType) => <EpiProperty for={props.items} component={renderProp} />;

const Items = styled.div({ display: 'flex', overflow: 'auto' });

const ItemsContainer = styled.div({ display: 'flex', justifyContent: 'center' });

const Title = styled.h2({
  fontWeight: 'bold',
  fontSize: beta,
  textAlign: 'center',
  marginBottom: small,
});

const Container = styled.div({
  [minMediumMediaQuery]: {
    justifyContent: 'center',
  },
});
